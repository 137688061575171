import { inject, Injectable, signal } from '@angular/core';
import { ApiService } from '@features/api';
import { Booking } from '@features/bookings';
import { Ticket } from '@features/events';
import { map, Observable } from 'rxjs';
import {
  AddToBasketRequest,
  AddToBasketRequestTicket,
  Basket,
  PaymentIntent,
  ValidateCheckoutResponse,
} from '../models';

@Injectable({ providedIn: 'root' })
export class BasketService {
  #api = inject(ApiService);

  readonly #checkoutBooking = signal<Booking | null>(null);
  public checkoutBooking = this.#checkoutBooking.asReadonly();

  #ticketsCache = signal<Ticket[] | null>(null);
  readonly ticketsCache = this.#ticketsCache.asReadonly();

  addTickets(tickets: Ticket[]) {
    const payload = this.getAddToBasketPayload(tickets);
    return this.#api.post<Basket>('/basket', payload).pipe(map((basket) => this.mapBasket(basket)));
  }

  getBasket(): Observable<Basket> {
    return this.#api.get<Basket>('/basket').pipe(map((basket) => this.mapBasket(basket)));
  }

  validateCheckout() {
    return this.#api.get<ValidateCheckoutResponse>('/basket/checkout/validate');
  }

  cacheTickets(basketTickets: Ticket[]) {
    this.#ticketsCache.set(basketTickets);
  }

  clearCache() {
    this.#ticketsCache.set(null);
  }

  getPaymentIntent() {
    return this.#api.get<PaymentIntent>('/basket/checkout');
  }

  cacheCheckoutBooking(booking: Booking) {
    this.#checkoutBooking.set(booking);
  }

  private getAddToBasketPayload(tickets: Ticket[]): AddToBasketRequest {
    const payload = tickets.reduce((acc: AddToBasketRequestTicket[], ticket): AddToBasketRequestTicket[] => {
      const options = new Map();
      for (const option of ticket.options) {
        const counter = options.get(option()) ?? 0;
        options.set(option(), counter + 1);
      }

      const mappedTickets: AddToBasketRequestTicket[] = [];
      for (const [ticket_option, quantity] of options.entries()) {
        mappedTickets.push({
          ticket_type_id: ticket.id,
          quantity,
          ticket_option,
        });
      }

      return [...acc, ...mappedTickets];
    }, []);

    return { tickets: payload };
  }

  private mapBasket(basket: Basket): Basket {
    return {
      ...basket,
      ticket_hold_start_time: new Date(basket.ticket_hold_start_time),
      ticket_hold_end_time: new Date(basket.ticket_hold_end_time),
    };
  }
}
